import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  Renderer2,
  ViewChild,
  OnInit,
  AfterViewInit
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngxs/store';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  Subject,
  Subscription,
  distinctUntilChanged,
  filter,
  interval,
  map,
  takeUntil,
  tap
} from 'rxjs';
import { CallScoreService } from '../../shared/shared-module/services/call-score.service';
import { CommonService } from '../../shared/shared-module/services/common.service';
import { DataService } from '../../shared/shared-module/services/data.service';
import { LoaderService } from '../services/loader.service';
import { ToastMessageService } from '../services/toast.message.service';
import { WarningService } from '../services/warning.service';
import { ToasterModel } from './../manager-dashboard/store/toaster/toaster.model';
import { CallScoreComponent } from './call-score/call-score.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app';
  $eventBus;
  instantMeetingEnable = false;
  public showErrorScreen = false;
  public miniStudio = false;
  public showErrorScreen_401 = true;
  public showErrorScreen_404 = false;
  public showErrorScreen_500 = false;
  public isLoader = false;
  public loadingSubscription: Subscription;
  @ViewChild(CallScoreComponent, { static: true })
  CallScoreComponent: CallScoreComponent;
  isRefreshEnable = false;
  onEventHandler;
  showToasterMsg$: Observable<ToasterModel>;
  subscription = new Subject();
  ngOnInit() {
    this.showToasterMsg$ = this.store.select((state) => state.toasterstate);
    if (!this.$eventBus) {
      this.$eventBus = window.addEventListener(
        'message',
        this.onEventHandler,
        false
      );
    }
    this.updateServiceWorker();
    this.checkServiceWorkerUpdate();
    this.warningService.showWarning.subscribe((data) => {
      if (data?.id === 'conversation_thread') {
        confirm('Changes you made may not be saved.');
      }
    });

    this.commonService.isLoggedIn
      .pipe(distinctUntilChanged(), takeUntil(this.subscription))
      .subscribe((data) => {
        if (data) {
          this.globalNotificationWebsocket();
        }
      });
    if (this.dataService.getUserData()) {
      this.globalNotificationWebsocket();
    }
  }

  ngAfterViewInit() {
    this.loadingSubscription = this.loaderService.showPageloader
      .pipe(distinctUntilChanged(), takeUntil(this.subscription))
      .subscribe((value) => {
        this.isLoader = value;
        if (
          this.isLoader === false &&
          this.callScoreService.callScoreData.length > 0 &&
          this.callScoreService.showCallScorePopup === true
        ) {
          this.CallScoreComponent.callScore.show();
        }
        this.cd.detectChanges();
      });
  }
  constructor(
    public toastMessageService: ToastMessageService,
    public commonService: CommonService,
    public ref: ApplicationRef,
    public router: Router,
    public route: ActivatedRoute,
    public loaderService: LoaderService,
    public dataService: DataService,
    private renderer: Renderer2,
    public callScoreService: CallScoreService,
    private cd: ChangeDetectorRef,
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private toastr: ToastrService,
    private warningService: WarningService,
    private store: Store,
    private cableService: ActionCableService
  ) {
    this.renderer.addClass(document.body, 'green');
    this.router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationEnd) {
            this.miniStudio = event.url.indexOf('buildcard') > -1;

            if (!event.url.startsWith('/projects/')) {
              if (
                localStorage.getItem('force_redirection') != null &&
                localStorage.getItem('force_redirection') !== ''
              ) {
                localStorage.removeItem('force_redirection');
              }

              if (
                sessionStorage.getItem('force_redirection') != null &&
                sessionStorage.getItem('force_redirection') !== ''
              ) {
                sessionStorage.removeItem('force_redirection');
              }
            }
          }

          // loader for lazy loaded modules
          if (event instanceof RouteConfigLoadStart) {
            this.loaderService.show();
          } else if (
            event instanceof NavigationCancel ||
            event instanceof NavigationEnd
          ) {
            this.loaderService.hide();
          }
        }),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary')
      )
      .subscribe(() => {
        if (localStorage.getItem('force_redirection')) {
          sessionStorage.setItem(
            'force_redirection',
            localStorage.getItem('force_redirection')
          );
          localStorage.removeItem('force_redirection');
        }
      });

    this.onEventHandler = this.onReceiveEventHandler.bind(this);
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  onReceiveEventHandler(e) {
    if (e?.data === 'show-loader') {
      this.loaderService.show();
    } else if (e?.data === 'hide-loader') {
      this.loaderService.hide();
    } else if (e?.data === 'toastr-loader') {
      this.toastr.success(
        'Oops Something went wrong. Please try again!',
        'Error',
        {
          disableTimeOut: false,
          tapToDismiss: false,
          positionClass: 'toast-top-right',
          toastClass: 'toast-icon custom-toast-error',
          timeOut: 2000
        }
      );
    }
  }

  ngOnDestory() {
    this.loadingSubscription.unsubscribe();
    this.subscription.next(null);
    this.subscription.complete();
    if (this.$eventBus) {
      window.removeEventListener('message', this.onEventHandler, false);
    }
  }

  closeSaveTost() {
    this.toastMessageService.showToastMsg = false;
  }

  updateServiceWorker() {
    try {
      if (!this.swUpdate.isEnabled) {
        return;
      }
      this.swUpdate.available.subscribe(() => {
        this.isRefreshEnable = true;
      });
    } catch (error) {
      console.error('SW Error = ', error);
    }
  }

  checkServiceWorkerUpdate() {
    try {
      this.appRef.isStable.subscribe((isStable) => {
        if (isStable) {
          const timeInterval = interval(10 * 60 * 1000); // 8 * 60 * 60 * 1000

          timeInterval.subscribe((data) => {
            if (data > 0 && data < 2)
              this.swUpdate.checkForUpdate().catch(() => {}); // eslint-disable-line
            clearInterval(data);
          });
        }
      });
    } catch (e) {
      console.error('error =', e);
    }
  }

  onHardRefresh() {
    window.location.reload();
  }

  ngCableChannel: Channel;
  instantMeeting = {
    meeting_name: '',
    start_time: '',
    url: '',
    id: ''
  };
  globalNotificationWebsocket() {
    // Open a connection and obtain a reference to the channel
    this.ngCableChannel = this.cableService
      .cable(this.dataService.getWebSocketUrl())
      .channel('GlobalNotificationChannel', {
        email: this.dataService.getUserData().email
      });

    this.ngCableChannel
      .received()
      .pipe(distinctUntilChanged(), takeUntil(this.subscription))
      .subscribe((message) => {
        console.log('message = ', message);
        if (
          message &&
          message['body'] &&
          message['body']['action'] == 'update' &&
          message['body']['channel_name'] ==
            `global_notification_channel_${
              this.dataService.getUserData().email
            }` &&
          message['body']['data'] &&
          message['body']['type'] === 'squad_meeting_instant'
        ) {
          if (message['body']['data']['meeting_status'] === 'close_popups') {
            console.log('close popup');
            this.instantMeetingEnable = false;
          } else {
            console.log('open popup');
            this.instantMeetingEnable = true;
            this.instantMeeting = message['body']['data'];
            this.instantMeeting['id'] = message['body']['id'];
            setTimeout(() => {
              this.instantMeetingEnable = false;
            }, 300000);
          }
        } else if (
          message &&
          message['body'] &&
          message['body']['action'] == 'update' &&
          message['body']['channel_name'] ==
            `global_notification_channel_${
              this.dataService.getUserData().email
            }` &&
          message['body']['data'] &&
          message['body']['type'] === 'project_download_url'
        ) {
          console.log('websocket url - ', message['body']['data']['url']);
          window.open(message['body']['data']['url'], '_blank');
          this.toastMessageService.showToastMessage(
            `${message['body']['data']['filename']} downloaded successfully.`,
            4000
          );
          this.commonService.notifyDownloadListViewComplete.next(true);
        }
      });
  }

  navigateTo(url) {
    this.closeInstantModelPopup();
    window.open(url, '_blank');
  }

  closeInstantModelPopup() {
    this.instantMeetingEnable = false;
    this.callScoreService
      .closeInstantNotificationPopup(this.instantMeeting['id'])
      .subscribe(() => {
        this.instantMeetingEnable = false;
      });
  }
}
