export class Constants {
  static apiEndPoints = {
    productListUrl:
      'https://api-productlisting.engineer.ai/products?query=[query]',
    signup: 'registrations/sign_up',
    login: 'sessions/sign_in',
    code_repo: 'code_repos',
    add_new_templates: 'templates',
    forgot: 'registrations/forgot_password',
    reset: 'registrations/reset_password',
    get_post: 'projects',
    getContractData: 'contract',
    client: 'clients',
    user_projects: 'user_projects',
    get_promotion: 'promotion/fetch_promotion_details',
    manager: 'admins',
    rawUser: 'admins/fetch_users',
    inviteManager: 'admins/invite',
    update_user_details: 'users/',
    cred: 'users/change_password',
    features: 'features/bundle_details',
    todos: 'todos/',
    BRACKETS: '[]',
    add_new_feature: 'features',
    timezone: 'timezones',
    getAssigneeList: 'projects/[project_id]/todos/new',
    getMeetings: 'projects/[project_id]/meetings',
    createBuild: 'projects/[project_id]/builds',
    createTodo: 'projects/[project_id]/todos',
    updateTodo: 'projects/[project_id]/todos/',
    updateMeeting: 'projects/[project_id]/meetings/',
    updateAllRecurringMeeting:
      'projects/[project_id]/meetings/[meeting_id]/reccurrences_update',
    getTodos: 'projects/[project_id]/todos?',
    getTodo: 'projects/[project_id]/todos/[todo_id]',
    getComments: 'todos/[todo_id]/comments',
    getTimeline: 'todos/[task_id]/timeline',
    projectAssign: 'projects/assign',
    getEpicActivity: 'projects/[project_id]/trackers/epic_feed',
    update_projects: 'projects/[project_id]',
    fetch_partners_details: 'partners/show',
    getideaboards: 'projects/[project_id]/ideaboards?filter=',
    getVideoTags:
      'projects/[project_id]/meetings/[meeting_id]/feature_taggings',
    getAllPods: 'pods',
    myTask: 'my_tasks',

    frontapp_embedding: 'fronts/front_embedding',
    frontapp_polling: 'projects/[project_id]/front_last_inbound',
    resync:
      'projects/[project_id]/allocations/[allocation_id]/sync_allocation_data_to_tracker',
    getSidebarProjects: 'projects/sidebar_projects',
    getFeaturesByIds: 'pm_dashboard/features/feature_data',
    add_delay: 'projects/[project_id]/releases/[release_id]/internal_delays',
    deallocatedFeatures: 'deallocated_features',
    getTrackerStoryCompletedFeatures:
      'external/projects/[project_id]/features/feature_story_completions',
    getProjectFeatureTimeline:
      'projects/[project_id]/builder_card_features/[build_card_feature_id]/notes/notes_timeline',
    getSpecCallTranscriptsStatus:
      'spec_calls/[spec_id]/transcripts/transcript_status',
    getSpecCallTranscriptsData:
      'spec_calls/[spec_id]/transcripts/transcript_data',
    generateTranscriptForSpec:
      'spec_calls/[spec_id]/transcripts/retry_call_transcription',
    resendDockerIDEs: 'external/projects/[project_id]/resend_docker_ides',
    getTrackerStoryAccepted: 'external/projects/[project_id]/stories_accepted',
    getTranscriptStatusForMeeting:
      'meetings/[meeting_id]/transcripts/transcript_status',
    getTranscriptDataForMeeting:
      'meetings/[meeting_id]/transcripts/transcript_data',
    generateTranscriptDataForMeeting:
      'meetings/[meeting_id]/transcripts/retry_call_transcription',
    getSquadMeeting: 'pods/[project_id]/meetings',
    postSquadMeeting: 'pods/[project_id]/meetings',
    getProjectBriefTimeline: 'projects/[project_id]/project_brief_timelines',
    updateSquadMeeting: 'pods/[squad_id]/meetings/',
    updateSquadAllRecurringMeeting:
      'pods/[squad_id]/meetings/[meeting_id]/reccurrences_update',
    getSimilarBuildCard: 'spec_calls/[specCallId]/similar_projects',
    getSimilarBuildCardForProject: 'projects/[projectId]/similar_projects',
    getPinnedBuildCard: 'projects/[projectId]/pinned_projects',
    deletePinnedBuildCard: 'projects/[projectId]/pinned_projects/',
    getProjectTransactionLogFeatures: 'projects/[project_id]/feature_logs',
    getProjectTransactionLogFeaturesLogs:
      'projects/[project_id]/feature_logs/data_logs',
    deleteTimeSlot:
      'users/[user_id]/available_dates/[available_dates_id]/time_slots/[time_slots_id]',
    deleteDateSlot: 'users/[user_id]/available_dates/[available_dates_id]',
    updateDateSlot: 'users/[user_id]/update_available_dates',
    deleteAssociates: 'projects/[project_id]/client_projects/bulk_destroy',
    deleteBulkGuestUsers: 'projects/[project_id]/user_projects/bulk_destroy',
    brex_email:
      'projects/[project_id]/accounts/[account_id]/send_mail_via_marketplace',
    getProjectDetail: 'projects/[project_id]/project_details',
    updateMeetingSpeakerName:
      'meetings/[meeting_id]/transcripts/update_speaker_mapping',
    updateSpecSpeakerName:
      'spec_calls/[spec_id]/transcripts/update_speaker_mapping',
    generateTransactionLogReport:
      'projects/[project_id]/feature_logs/create_transaction_log_report',
    getZoomToken: 'meetings/zoom_meeting_token',
    getRefreshZoomToken: 'meetings/zoom_meeting_token',
    getDetailedBuildCard: 'projects/[project_id]/detailed_build_card_pdfs',
    createDetailedBuildCard: 'projects/[project_id]/create_project_pdfs',
    getProjectAllocatedExperts:
      'allocations?project_id=[project_id]&stab=accepted',
    getCatalogueNominatedBuildcard: 'projects/nominated_bcs_for_upgrade',
    createblockUpgradeRequest: 'projects/initiate_block_upgrade_to_tracker',
    create_cr_Request: 'projects/[project_id]/feature_change_requests',
    getJourney: 'projects/journey_build_card_features',
    getPhaseFeatureDetails: 'projects/[project_id]/phase_details',
    getJourneyFeatures:
      'projects/journey_build_card_features/[project_id]/project',
    getJourneyFeaturePercentage:
      'projects/[project_id]/journey_build_card_features/dls_feature',
    getDetailedKickOff: 'projects/[project_id]/build_card_kick_off_pdfs',
    createDetailedKickOff: 'projects/[project_id]/create_kickoff_project_pdfs',
    stopBcareAutoRenewal:
      'projects/[project_id]/builder_care_stop_requests/generate_stop_request',
    getAssignedSubStatuses: 'sub_statuses',
    getSpecCallAttachments: 'spec_calls/[spec_id]/spec_call_attachments',
    getSubstatus: 'projects/[project_id]/sub_statuses/list',
    getOverdueTodos: 'projects/[project_id]/todos/overdue_todos',
    updatePaymentsFrequencyTimeline:
      'projects/[project_id]/log_frequency_change_to_timeline',
    deleteBuildCardTag: 'projects/[projectId]',
    generatePdf: 'projects/generate_pdf',
    getTodosByIds: 'projects/[projectId]/todos/fetch_todos',
    getUnpaidBuildCardList: 'clients/[client_id]/client_projects',
    saveProjectsColumnPreferences: 'admins/save_column_preferences',
    getToDoLabels: 'master_todo_labels/list',
    createTodoLabel: 'master_todo_labels',
    updateTodoLabel: 'master_todo_labels/[todoId]',
    getBCTypes: 'build_card_types/list',
    getBcTagList: 'build_card_types/[typeId]/build_card_type_tags',
    createBcTagLabel: 'build_card_types/create_build_card_type_tags',
    updateBcTagLabel: 'build_card_types/[typeId]/build_card_type_tags/[tagId]',
    update_spec_action: 'jfx_note_details/',
    feature_difference: 'feature_difference',
    projectPrimaryUsers: 'projects/primary_users',
    getAuthenticatedBmeetUrl: 'projects/meetings/meet_recording'
  };

  static CMTApiEndpoints = {
    getFeatureSkillsBlocks:
      'features/available_blocks?builder_studio_feature_id=[builder_studio_feature_id]'
  };

  static studioApiEndpoints = {
    getPaymentLink: 'pm_dashboard/build_card/build_card_id/get_payment_link',
    getNatashaChatLogs:
      'pm_dashboard/build_card/[build_card_id]/customer_natasha_interaction',
    getPaymentStatus:
      'pm_dashboard/build_card/[build_card_id]/installment_count',
    updatePaymentStatus:
      'pm_dashboard/build_card/[build_card_id]/update_payment_status',
    switchToMonthlyCycle:
      'pm_dashboard/build_card/[build_card_id]/change_billing_cycle',
    getInstallments: 'pm_dashboard/build_cards/[build_card_id]/installments',
    getBuildcardInfo: 'pm_dashboard/build_card/[build_card_id]/build_card_info',
    updateCollectionProcess:
      'pm_dashboard/build_cards/[build_card_id]/collection_process',
    refundInstallments: 'pm_dashboard/installments/bulk_refund',
    addLateFee:
      'pm_dashboard/api/v1/installments/[installment_id]/payment_delay_charge',
    waiveOffLateFee:
      'pm_dashboard/installments/[installment_id]/bulk_waive_off',
    uploadBulkRefundAttachment: 'pm_dashboard/installment_attachments'
  };

  static COCKPIT_WINDOW_MESSAGE = {
    call_schedule: 'Call Scheduled!',
    call_was_schedule: 'This call was scheduled at',
    meeting_start: 'START MEETING'
  };

  static userRole = {
    pm_lead: 'pm_lead',
    pm: 'pm',
    gpm: 'gpm'
  };

  static PARAM_CONSTANTS = {
    DECIMAL: '.'
  };
  static VIDEO_MIN_SNIP_TIME = {
    TIME: 2
  };
  static ROR_REACT_ICONS = {
    RUBY: 'https://publicpmdashboardassets.blob.core.windows.net/assets-pm-production/Ruby.svg',
    REACT:
      'https://publicpmdashboardassets.blob.core.windows.net/assets-pm-production/React.svg'
  };

  static frontappEndPoints = {
    getConversationById: 'conversations/[conversation_id]',
    getConversationByTag: 'conversations/search/[query]',
    getMessagesByConversationId: 'conversations/[conversation_id]/messages',
    getCommentsByConversationId: 'conversations/[conversation_id]/comments',
    getEventsByConversationId: 'conversations/[conversation_id]/events',
    getMessageById: 'messages/[message_id]',

    createComment: 'conversations/[conversation_id]/comments',
    createConversation: 'channels/[channel_id]/messages',
    createConversationReply: 'conversations/[conversation_id]/messages',
    createDraftReply: 'conversations/[conversation_id]/drafts',
    createDraft: 'channels/[channel_id]/drafts',

    editDraft: 'drafts/[message_id]/',
    deleteDraft: 'drafts/[draft_id]',
    getInboxTeammates: 'inboxes/[inbox_id]/teammates',
    updateConversationStatus: 'conversations/[conversation_id]',
    getTeammateSignature: 'teammates/[teammate_id]/signatures',
    getInbox: 'inboxes/[inbox_id]',
    getChannel: 'channels/[channel_id]'
  };

  static hhmmOfDay = [
    '12:00',
    '12:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30'
  ];

  static hhOfDay = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17'
  ];

  static categorySubCategoryMap = [
    {
      category: 'Sales <>Customer communication gap',
      subCategory: []
    },
    {
      category: 'Sales<>Delivery communication gap ',
      subCategory: []
    },
    {
      category: 'Capacity Issues',
      subCategory: [
        'Delay onboarding designer',
        'Delay onboarding developer',
        'Delay onboarding tester',
        'Non responsiveness',
        'Unplanned leaves',
        'Inexperienced expert'
      ]
    },
    {
      category: 'Product Issues',
      subCategory: [
        'IDE issue',
        '360 issue',
        'UIW issue',
        'Tracker issue',
        'Whiteboard issue',
        'Payment failure issue',
        'Cloud Set-up Delays'
      ]
    },
    {
      category: 'Sensei Bandwidth',
      subCategory: []
    },
    {
      category: 'Ninja Bandwidth',
      subCategory: []
    },
    {
      category: 'SRE being Non-Responsive',
      subCategory: []
    },
    {
      category: 'Care/Cloud Contract Delays',
      subCategory: []
    },
    {
      category: 'Productologist Bandwidth',
      subCategory: []
    },
    {
      category: 'Understanding gaps in Scope',
      subCategory: []
    },
    {
      category: 'Design screens are missed',
      subCategory: []
    },
    {
      category: 'Feature mismatched',
      subCategory: []
    },
    {
      category: 'Features are missing',
      subCategory: []
    },
    {
      category: 'Finance Delays ',
      subCategory: [
        'Payment is not updated',
        'Buildcard is not correctly marked as offline paid',
        'VAT issues',
        'Invoice Receipt Issue',
        'Tax related Issue'
      ]
    },
    {
      category: 'Technical issues',
      subCategory: [
        'Automation Tooling Validation fixes',
        'SonarQube issues',
        'Unit Test cases',
        'Dependency on third party API',
        'Mobile Build failure issues',
        'Code Quality issues',
        'Pipeline Failure',
        'Deployment issues',
        'Infra limitations'
      ]
    },
    {
      category: 'Delay in pausing due to BB development',
      subCategory: []
    }
  ];
  static skillsList = [
    'Blockchain',
    '.NET',
    'Node',
    'Java',
    'Rails/ROR',
    'GoLang',
    'C#',
    'C',
    'PHP/Wordpress',
    'Machine learning',
    'Node JS',
    'Python',
    'Rails/ROR',
    'React Native',
    'Android',
    'iOS/Swift',
    'Angular',
    'React',
    'HTML/CSS',
    'React JS'
  ];

  static toastMessages = {
    error: 'Something went wrong. Please try again !',
    error2: 'Something went wrong.',
    maxFilesUploadError: 'Maximum 5 files can be uploaded.'
  };

  static designationMap = {
    productologistDesignations: [
      'CPE',
      'SCPE',
      'Graduate Project Manager',
      'Associate Productologist',
      'Tribe Lead - Productologist'
    ],
    ninjaDesignations: [
      'CPM',
      'SCPM',
      'Associate Ninja',
      'Tribe Lead - Ninja',
      'Head of Ninja'
    ],
    senseiDesignations: [
      'CTE',
      'SCTE',
      'Tribe Lead - Sensei',
      'Head of Sensei'
    ],
    successGeniusDesignations: ['Customer Success Manager'],
    financeDesignations: ['Sr. Finance', 'Finance Executive']
  };
  static UAEPartner = 'uae_partner';

  static meetingFromTimeArr() {
    const timeArray = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour; // Convert hour to 12-hour format
        const period = hour >= 12 ? 'pm' : 'am'; // Determine if it's am or pm
        const formattedMinute = (minute < 10 ? '0' : '') + minute; // Add leading zero if minute is less than 10
        // Push the formatted time to the array
        timeArray.push(`${formattedHour}:${formattedMinute}${period}`);
      }
    }
    return timeArray;
  }

  static meetingRepeatOptions() {
    return [
      { name: 'Event does not repeat', key: 'single' },
      { name: 'Every weekday (Mon to Fri)', key: 'weekday' },
      { name: 'Daily (Mon to Sun)', key: 'daily' },
      { name: 'Weekly', key: 'weekly' },
      { name: 'Monthly', key: 'monthly' }
    ];
  }

  static internalMembersSuffix = [
    '@builder.ai',
    '@engineer.ai',
    '@yopmail.com',
    '@x.builder.ai'
  ];

  static priorityList = [
    // { key: '', value: 'Select Priority', icon: '' },
    { key: 'P1', icon: 'P1.svg', value: 'P1 - Blocker' },
    { key: 'P2', icon: 'P2.svg', value: 'P2 - Critical' },
    { key: 'P3', icon: 'P3.svg', value: 'P3 - Major' },
    { key: 'P4', icon: 'P4.svg', value: 'P4 - Minor' },
    { key: 'P5', icon: 'P5.svg', value: 'P5 - Trivial' }
  ];

  static eventAndDeweyCodes = {
    liveChatInitiated: {
      eventName: 'live_chat_initiated',
      deweyCode: 'C1.2.25.1'
    },
    scheduler_date_selected: {
      eventName: 'scheduler_date_selected',
      deweyCode: 'C1.3.37.9'
    },
    scheduler_time_selected: {
      eventName: 'scheduler_time_selected',
      deweyCode: 'C1.3.37.10'
    },
    scheduler_timezone_selected: {
      eventName: 'scheduler_timezone_selected',
      deweyCode: 'C1.3.37.11'
    },
    scheduler_confirm_clicked: {
      eventName: 'scheduler_confirm_clicked',
      deweyCode: 'C1.3.37.12'
    },
    scheduler_reset_clicked: {
      eventName: 'scheduler_reset_clicked',
      deweyCode: 'C1.3.37.13'
    },
    build_card_clicked: {
      eventName: 'build_card_clicked',
      deweyCode: 'D2.1.2.1'
    },
    basic_info_screen_clicked: {
      eventName: 'basic_info_screen_clicked',
      deweyCode: 'D2.1.2.2'
    },
    build_card_brief_clicked: {
      eventName: 'build_card_brief_clicked',
      deweyCode: 'D2.1.2.3'
    },
    feature_notes_clicked: {
      eventName: 'feature_notes_clicked',
      deweyCode: 'D2.1.2.4'
    },
    project_status_clicked: {
      eventName: 'project_status_clicked',
      deweyCode: 'D2.1.2.5'
    },
    team_allocation_clicked: {
      eventName: 'team_allocation_clicked',
      deweyCode: 'D2.1.2.6'
    },
    expert_allocation_clicked: {
      eventName: 'expert_allocation_clicked',
      deweyCode: 'D2.1.2.7'
    },
    squad_allocation_clicked: {
      eventName: 'squad_allocation_clicked',
      deweyCode: 'D2.1.2.8'
    },
    whiteboard_clicked: {
      eventName: 'whiteboard_clicked',
      deweyCode: 'D2.1.2.9'
    },
    swimlanes_clicked: {
      eventName: 'swimlanes_clicked',
      deweyCode: 'D2.1.2.10'
    },
    toolkit_clicked: {
      eventName: 'toolkit_clicked',
      deweyCode: 'D2.1.2.11'
    },
    inbox_clicked: { eventName: 'inbox_clicked', deweyCode: 'D2.1.2.12' },
    meetings_clicked: {
      eventName: 'meetings_clicked',
      deweyCode: 'D2.1.2.13'
    },
    transaction_logs_clicked: {
      eventName: 'transaction_logs_clicked',
      deweyCode: 'D2.1.2.14'
    },
    delay_history_clicked: {
      eventName: 'delay_history_clicked',
      deweyCode: 'D2.1.2.15'
    },
    todo_s_queries_clicked: {
      eventName: 'todo_s_queries_clicked',
      deweyCode: 'D2.1.2.16'
    },
    documents_clicked: {
      eventName: 'documents_clicked',
      deweyCode: 'D2.1.2.17'
    },
    release_planner_clicked: {
      eventName: 'release_planner_clicked',
      deweyCode: 'D2.1.2.18'
    },
    builds_and_release_clicked: {
      eventName: 'builds_and_release_clicked',
      deweyCode: 'D2.1.2.19'
    },
    code_repository_clicked: {
      eventName: 'code_repository_clicked',
      deweyCode: 'D2.1.2.20'
    },
    payment_details_clicked: {
      eventName: 'payment_details_clicked',
      deweyCode: 'D2.1.2.21'
    },
    customer_insights_clicked: {
      eventName: 'customer_insights_clicked',
      deweyCode: 'D2.1.2.22'
    },
    ideaboard_clicked: {
      eventName: 'ideaboard_clicked',
      deweyCode: 'D2.1.2.23'
    },
    associated_buildcards_clicked: {
      eventName: 'associated_buildcards_clicked',
      deweyCode: 'D2.1.2.24'
    },
    feature_notes_start_thread_clicked: {
      eventName: 'feature_notes_start_thread_clicked',
      deweyCode: 'D2.1.2.25'
    },
    blocked_tab_clicked: {
      eventName: 'blocked_tab_clicked',
      deweyCode: 'D2.1.2.26'
    },
    freemium_tab_clicked: {
      eventName: 'freemium_tab_clicked',
      deweyCode: 'D2.1.2.27'
    },
    unpaid_tab_clicked: {
      eventName: 'unpaid_tab_clicked',
      deweyCode: 'D2.1.2.28'
    },
    completed_tab_clicked: {
      eventName: 'completed_tab_clicked',
      deweyCode: 'D2.1.2.29'
    },
    paid_tab_clicked: {
      eventName: 'paid_tab_clicked',
      deweyCode: 'D2.1.2.30'
    },
    builder_care_tab_clicked: {
      eventName: 'builder_care_tab_clicked',
      deweyCode: 'D2.1.2.31'
    },
    stopped_tab_clicked: {
      eventName: 'stopped_tab_clicked',
      deweyCode: 'D2.1.2.32'
    },
    assigned_tab_clicked: {
      eventName: 'assigned_tab_clicked',
      deweyCode: 'D2.1.2.33'
    },
    ongoing_tab_clicked: {
      eventName: 'ongoing_tab_clicked',
      deweyCode: 'D2.1.2.34'
    },
    build_card_status_clicked: {
      eventName: 'build_card_status_clicked',
      deweyCode: 'D2.1.2.35'
    },
    buildcard_cost_updated: {
      eventName: 'buildcard_cost_updated',
      deweyCode: 'D2.1.2.36'
    },
    change_status_clicked: {
      eventName: 'change_status_clicked',
      deweyCode: 'D2.1.2.37'
    },
    buildcard_brief_updated: {
      eventName: 'buildcard_brief_updated',
      deweyCode: 'D2.1.2.38'
    },
    ongoing_ideaboard_clicked: {
      eventName: 'ongoing_ideaboard_clicked',
      deweyCode: 'D2.1.2.39'
    },
    document_tab_clicked: {
      eventName: 'document_tab_clicked',
      deweyCode: 'D2.1.2.40'
    },
    signup_clicked: {
      eventName: 'signup_clicked',
      deweyCode: 'D2.1.2.41'
    },
    create_build_clicked: {
      eventName: 'create_build_clicked',
      deweyCode: 'D2.1.2.42'
    },
    ongoing_documents_clicked: {
      eventName: 'ongoing_documents_clicked',
      deweyCode: 'D2.1.2.43'
    },
    ongoing_team_allocation_clicked: {
      eventName: 'ongoing_team_allocation_clicked',
      deweyCode: 'D2.1.2.44'
    },
    code_repo_clicked: {
      eventName: 'code_repo_clicked',
      deweyCode: 'D2.1.2.45'
    },
    studio_call_clicked: {
      eventName: 'studio_call_clicked',
      deweyCode: 'D2.1.2.46'
    },
    ideaboard_created_by_team_clicked: {
      eventName: 'ideaboard_created_by_team_clicked',
      deweyCode: 'D2.1.2.47'
    },
    ongoing_basic_info_screen_clicked: {
      eventName: 'ongoing_basic_info_screen_clicked',
      deweyCode: 'D2.1.2.48'
    },
    ongoing_builds_and_release_clicked: {
      eventName: 'ongoing_builds_and_release_clicked',
      deweyCode: 'D2.1.2.49'
    },
    ongoing_project_status_clicked: {
      eventName: 'ongoing_project_status_clicked',
      deweyCode: 'D2.1.2.50'
    },
    ongoing_meetings_clicked: {
      eventName: 'ongoing_meetings_clicked',
      deweyCode: 'D2.1.2.51'
    },
    managers_clicked: {
      eventName: 'managers_clicked',
      deweyCode: 'D2.1.2.52'
    },
    login_clicked: { eventName: 'login_clicked', deweyCode: 'D2.1.2.53' },
    move_within_group: {
      eventName: 'move_within_group',
      deweyCode: 'D2.1.2.54'
    },
    home_page_visted: {
      eventName: 'home_page_visted',
      deweyCode: 'D2.1.2.55'
    },
    ongoing_todo_s_queries_clicked: {
      eventName: 'ongoing_todo_s_queries_clicked',
      deweyCode: 'D2.1.2.56'
    },
    login_successful: {
      eventName: 'login_successful',
      deweyCode: 'D2.1.2.57'
    },
    blocked_project_status_clicked: {
      eventName: 'blocked_project_status_clicked',
      deweyCode: 'D2.1.2.58'
    },
    new_project_status_clicked: {
      eventName: 'new_project_status_clicked',
      deweyCode: 'D2.1.2.59'
    },
    squad_clicked: { eventName: 'squad_clicked', deweyCode: 'D2.1.2.60' },
    partners_clicked: {
      eventName: 'partners_clicked',
      deweyCode: 'D2.1.2.61'
    },
    project_created: {
      eventName: 'project_created',
      deweyCode: 'D2.1.1.1'
    },
    new_build_card_time_spent: {
      eventName: 'new_build_card_time_spent',
      deweyCode: 'C1.3.23.1'
    },
    team_allocation: {
      eventName: 'team_allocation',
      deweyCode: 'D2.2'
    },
    add_manager_clicked: {
      eventName: 'add_manager_clicked',
      deweyCode: 'D2.1.2.85'
    },
    pod_allocation_update: {
      eventName: 'pod_allocation_update',
      deweyCode: 'D2.1.2.93'
    },
    ongoing_build_card_clicked: {
      eventName: 'ongoing_build_card_clicked',
      deweyCode: 'D2.1.2.94'
    },
    team_allocation_create: {
      eventName: 'team_allocation_create',
      deweyCode: 'D2.2.2'
    },
    blocked_build_card_clicked: {
      eventName: 'blocked_build_card_clicked',
      deweyCode: 'D2.1.2.95'
    },
    pod_allocation_create: {
      eventName: 'pod_allocation_create',
      deweyCode: 'D2.1.2.96'
    },
    blocked_build_card_time_spent: {
      eventName: 'blocked_build_card_time_spent',
      deweyCode: 'D2.1.2.86'
    },
    blocked_team_allocation_clicked: {
      eventName: 'blocked_team_allocation_clicked',
      deweyCode: 'D2.2.3'
    },
    client_info_cancel_clicked: {
      eventName: 'client_info_cancel_clicked',
      deweyCode: 'D2.1.2.87'
    },
    meeting_scheduled: {
      eventName: 'meeting_scheduled',
      deweyCode: 'D2.1.2.97'
    },
    upload_file_upload_clicked: {
      eventName: 'upload_file_upload_clicked',
      deweyCode: 'D2.1.2.88'
    },
    login_unsuccessful: {
      eventName: 'login_unsuccessful',
      deweyCode: 'D2.1.2.89'
    },
    archive_tab_clicked: {
      eventName: 'archive_tab_clicked',
      deweyCode: 'D2.1.2.98'
    },
    new_folder_create_clicked: {
      eventName: 'new_folder_create_clicked',
      deweyCode: 'D2.1.2.99'
    },
    project_updated: {
      eventName: 'project_updated',
      deweyCode: 'D2.1.2.100'
    },
    team_allocation_update: {
      eventName: 'team_allocation_update',
      deweyCode: 'D2.2.4'
    },
    completed_build_card_time_spent: {
      eventName: 'completed_build_card_time_spent',
      deweyCode: 'D2.1.2.101'
    },
    add_task_cancel: {
      eventName: 'add_task_cancel',
      deweyCode: 'D2.1.2.102'
    },
    completed_build_card_clicked: {
      eventName: 'completed_build_card_clicked',
      deweyCode: 'D2.1.2.103'
    },
    checklist_clicked: {
      eventName: 'checklist_clicked',
      deweyCode: 'D2.1.2.104'
    },
    sign_up_clicked: {
      eventName: 'sign_up_clicked',
      deweyCode: 'D2.1.2.104'
    },
    ongoing_invoices_clicked: {
      eventName: 'ongoing_invoices_clicked',
      deweyCode: 'D2.1.2.106.1'
    },
    cockpit_clicked: {
      eventName: 'cockpit_clicked',
      deweyCode: 'D2.1.2.106.8'
    },
    pod_allocation: {
      eventName: 'pod_allocation',
      deweyCode: 'D2.1.2.107.5'
    },
    call_type_clicked: {
      eventName: 'call_type_clicked',
      deweyCode: 'D2.1.2.109'
    },
    ongoing_build_card_time_spent: {
      eventName: 'ongoing_build_card_time_spent',
      deweyCode: 'D2.1.2.110'
    },
    spec_call_booked: {
      eventName: 'spec_call_booked',
      deweyCode: 'C3.1'
    },
    add_folder_clicked: {
      eventName: 'add_folder_clicked',
      deweyCode: 'D2.1.2.111'
    },
    add_to_buildcard_clicked: {
      eventName: 'add_to_buildcard_clicked',
      deweyCode: 'D2.1.2.112'
    },
    upload_file_clicked: {
      eventName: 'upload_file_clicked',
      deweyCode: 'D2.1.2.113'
    },
    add_task_create: {
      eventName: 'add_task_create',
      deweyCode: 'D2.1.2.114'
    },
    team_allocation_delete: {
      eventName: 'team_allocation_delete',
      deweyCode: 'D2.2.5'
    },
    set_up_add_account_clicked: {
      eventName: 'set_up_add_account_clicked',
      deweyCode: 'D2.1.2.115'
    },
    client_info_add_clicked: {
      eventName: 'client_info_add_clicked',
      deweyCode: 'D2.1.2.116'
    },
    pod_allocation_delete: {
      eventName: 'pod_allocation_delete',
      deweyCode: 'D2.1.2.117'
    },
    stopped_project_allocation_clicked: {
      eventName: 'stopped_project_allocation_clicked',
      deweyCode: 'D2.1.2.118'
    },
    custom_phase_added_clicked: {
      eventName: 'custom_phase_added_clicked',
      deweyCode: 'D2.1.2.119'
    },
    stopped_project_status_allocation_clicked: {
      eventName: 'stopped_project_status_allocation_clicked',
      deweyCode: 'D2.1.2.120'
    },
    project_completed: {
      eventName: 'project_completed',
      deweyCode: 'D2.1.2.121'
    },
    project_is_completed: {
      eventName: 'project_is_completed',
      deweyCode: 'D2.1.2.122'
    },
    move_to_another_group: {
      eventName: 'move_to_another_group',
      deweyCode: 'D2.1.2.123'
    },
    new_team_allocation_clicked: {
      eventName: 'new_team_allocation_clicked',
      deweyCode: 'D2.2.6'
    },
    team_create_new_idea_cancel_clicked: {
      eventName: 'team_create_new_idea_cancel_clicked',
      deweyCode: 'D2.1.2.124'
    },
    project_deleted: {
      eventName: 'project_deleted',
      deweyCode: 'D2.1.2.125'
    },
    add_squad_clicked: {
      eventName: 'add_squad_clicked',
      deweyCode: 'D2.1.2.126'
    },
    design_board_add_clicked: {
      eventName: 'design_board_add_clicked',
      deweyCode: 'D2.1.2.127'
    },
    custom_phase_cancel_clicked: {
      eventName: 'custom_phase_cancel_clicked',
      deweyCode: 'D2.1.2.128'
    },
    feature_tag_update: {
      eventName: 'feature_tag_update',
      deweyCode: 'D2.1.2.129'
    },
    team_create_new_idea_clicked: {
      eventName: 'team_create_new_idea_clicked',
      deweyCode: 'D2.1.2.130'
    },
    bb_status_change: {
      eventName: 'bb_status_change',
      deweyCode: 'D2.1.2.131'
    },
    blocked_project_status_allocation_clicked: {
      eventName: 'blocked_project_status_allocation_clicked',
      deweyCode: 'D2.1.2.132'
    },
    new_project_status_allocation_clicked: {
      eventName: 'new_project_status_allocation_clicked',
      deweyCode: 'D2.1.2.133'
    },
    new_basic_info_screen_clicked: {
      eventName: 'new_basic_info_screen_clicked',
      deweyCode: 'D2.1.2.134'
    },
    ongoing_project_status_allocation_clicked: {
      eventName: 'ongoing_project_status_allocation_clicked',
      deweyCode: 'D2.1.2.135'
    },
    blocked_basic_info_screen_clicked: {
      eventName: 'blocked_basic_info_screen_clicked',
      deweyCode: 'D2.1.2.136'
    },
    meeting_scheduled_on_squad: {
      eventName: 'meeting_scheduled_on_squad',
      deweyCode: 'D2.1.2.137'
    },
    custom_phase_create_clicked: {
      eventName: 'custom_phase_create_clicked',
      deweyCode: 'D2.1.2.138'
    },
    participants_added_via_cockpit: {
      eventName: 'participants_added_via_cockpit',
      deweyCode: 'D2.1.2.139'
    },
    build_card_created_via_cockpit: {
      eventName: 'build_card_created_via_cockpit',
      deweyCode: 'D2.1.2.140'
    },
    todo_queries_clicked: {
      eventName: 'todo_queries_clicked',
      deweyCode: 'D2.1.2.141'
    },
    checklist: { eventName: 'checklist', deweyCode: 'D2.1.2.142' },
    catalogue_clicked: {
      eventName: 'catalogue_clicked',
      deweyCode: 'D2.1.2.143'
    },
    document_clicked: {
      eventName: 'document_clicked',
      deweyCode: 'D2.1.2.144'
    },
    /*transaction_logs_clicked: { eventName:'transaction_logs_clicked',deweyCode: 'D2.1.2.92'  },*/
    feature_notes_thread_started_clicked: {
      eventName: 'feature_notes_thread_started_clicked',
      deweyCode: 'C6.5.20'
    },
    delay_added: { eventName: 'delay_added', deweyCode: 'D2.7.1.9' },
    accounts_clicked: {
      eventName: 'accounts_clicked',
      deweyCode: 'D2.1.2.90'
    },
    build_card_journey_clicked: {
      eventName: 'build_card_journey_clicked',
      deweyCode: 'D2.1.2.90'
    },
    feature_notes_thread_started: {
      eventName: 'feature_notes_thread_started',
      deweyCode: 'C6.5.19'
    },
    creators_info_accessed: {
      eventName: 'creators_info_accessed',
      deweyCode: 'F2.1'
    },
    feature_notes_thread_clicked: {
      eventName: 'feature_notes_thread_clicked',
      deweyCode: 'C6.5.18'
    },
    cloud_subscribe_click: {
      eventName: 'cloud_subscribe_click',
      deweyCode: 'D5.5.1'
    },
    cloud_user_range_select: {
      eventName: 'cloud_user_range_select',
      deweyCode: 'D5.5.2'
    },
    feature_notes_thread_resolved: {
      eventName: 'feature_notes_thread_resolved',
      deweyCode: 'C6.5.21'
    },
    repository_clicked: {
      eventName: 'repository_clicked',
      deweyCode: 'D2.3.1.15'
    },
    b_dial_clicked: { eventName: 'b_dial_clicked', deweyCode: 'D2.3.1.16' }
  };
}
