import { KeyValue } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { Angulartics2Segment } from 'angulartics2';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UAParser } from 'ua-parser-js';
import { ProjectMeetingEditComponent } from '../../../core/project-module/project-meetings-module/project-meeting-edit/project-meeting-edit.component';
import { ProjectModel } from '../models/project-model/project.model';
import { Constants } from '../utility/constants';
import moment from 'moment';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CommonService {
  isShowFeatureDifference = false;
  isFromJourneyFeature;
  featureNameClicked: '';
  clickedJourneyName: '';
  currentFeatureNotes;
  updatedFeatureNotes;
  resetFeatureNotes = new BehaviorSubject(null);
  currentJourneyNotes;
  updatedJourneyNotes;
  resetJourneyNotes = new BehaviorSubject(null);
  public leftPanelOpen = false;
  public showHomePage = false;
  public guidelineSlider = false;
  public locale = 'en-US';
  public currentStatus = '';
  public errorZohoId = '';
  public updatedUserImage = '';
  public ifViewTeamClicked = false;
  public rateValue: string;
  public skillValue;
  public managerListing = true;
  public selectedProjecId;
  public selectedProjectData;
  public selectedManagerId;
  public resetSucess = false;
  public showCurrentCommentSection;
  public tabStatus = 'pending';
  public meetingTabStatus = 'today';
  public newHeadertrue = false;
  public projectDetail: ProjectModel;
  public blockedReasonKey = false;
  public activateComponent;
  updateProjectDetail = new EventEmitter();
  updateManagerDetail = new EventEmitter();
  public notifyProjectClientSubject = new BehaviorSubject(null);
  public notifyProjectDetailSubject = new BehaviorSubject(null);
  public nitifyManagerSubject = new BehaviorSubject(null);
  public notifyTodosQueriesSubject = new BehaviorSubject(null);
  public notifyProjectInboxSubject = new BehaviorSubject(null);
  public notifyCommentSubject = new BehaviorSubject(null);
  public notifyAccountSetupSubject = new BehaviorSubject(null);
  public notifyDesignBoardSubject = new BehaviorSubject(null);
  public notifyChecklistRepoSubject = new BehaviorSubject(null);
  public notifyReleaseNotesSubject = new BehaviorSubject(null);
  public notifySlackSubject = new BehaviorSubject(null);
  public notifyBuilderPlannerSubject = new BehaviorSubject(null);
  public notifyFooterBuilderPlannerSubject = new BehaviorSubject(null);
  public notifyMyAccountSubject = new BehaviorSubject(null);
  public notifyAllocationsSubject = new BehaviorSubject(null);
  public notifyPhaseWiseFeedSubject = new BehaviorSubject(null);
  public notifyPartnersListSubject = new BehaviorSubject(null);
  public notifyRecommendedListSubject = new BehaviorSubject(null);
  public notifyDownloadTimeLineSubject = new BehaviorSubject(null);
  public timezones: Array<string>;
  public notifyBlockedReasonSubject = new BehaviorSubject(null);
  public notifyMeetingSubject = new BehaviorSubject(null);
  public notifyIdeaboardSubject = new BehaviorSubject(null);
  public notifyCurrentSelection = new BehaviorSubject(null);
  public notifyCurrentProjectIdSubject = new BehaviorSubject(null);
  public notifyBuildcardLatestDetailsSubject = new BehaviorSubject(null);
  notifyServiceRegionData = new BehaviorSubject(false);

  public projectMeetingEditComponent: ProjectMeetingEditComponent;
  public notifyIdeaBoard = new EventEmitter();
  public notifyBuildReleaseNote = new EventEmitter();
  public projectFilterData = {};
  public projectFilterApi;
  notifyActivityLodPDF = new BehaviorSubject(null);
  isUnsavedChanges = false;
  public isLoggedIn = new BehaviorSubject<boolean>(false);
  public notifyScopeChangeRequest = new BehaviorSubject<boolean>(false);
  notifyGetSpecCallData = new BehaviorSubject(false);
  public notifyDownloadListViewComplete = new BehaviorSubject(false);
  // Preserve original property order
  originalOrder = (
    a: KeyValue<number, string>, // eslint-disable-line
    b: KeyValue<number, string> // eslint-disable-line
  ): number => {
    return 0;
  };

  // Order by ascending property value
  valueAscOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.value.localeCompare(b.value);
  };

  // Order by descending property key
  keyDescOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  constructor(public angulartics2Segment: Angulartics2Segment) {
    if (!this.getCookiesByName('builder_cookie_id')) {
      this.setCookies('builder_cookie_id', this.uuid());
    }
    if (
      !this.getCookiesByName('builder_session_start_time') ||
      !this.getCookiesByName('builder_session_id')
    ) {
      this.setCookies('builder_session_id', this.uuid());
      this.setCookies('builder_session_start_time', moment().toString());
    }
    const dateFromCookies = this.getCookiesByName('builder_session_start_time'),
      dateString = moment(
        dateFromCookies,
        'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
        true
      ),
      now = moment(),
      minutes = now.diff(dateString, 'minutes');
    if (minutes >= 30) {
      this.setCookies('builder_session_id', this.uuid());
      this.setCookies('builder_session_start_time', moment().toString());
    }
  }

  public forceRedirect(hasQues?) {
    const specIdParam = sessionStorage.getItem('force_redirection');
    if (specIdParam != undefined && specIdParam != null && specIdParam != '') {
      return `${!hasQues ? '?' : '&'}forceid=${specIdParam}`;
    } else {
      return '';
    }
  }

  public trackSegmentEvent(name, prop, isProject?) {
    if (isProject) {
      prop['project_name'] = this.projectDetail ? this.projectDetail.name : '';
      prop['manager_name'] = this.getUserName();
    }
    this.angulartics2Segment.eventTrack(name, prop);
  }

  public notifyProjectClient(data) {
    if (data) {
      this.notifyProjectClientSubject.next(data);
    }
  }
  public notifyCurrentProjectId(data) {
    if (data) {
      this.notifyCurrentProjectIdSubject.next(data);
    }
  }

  public notifyBuildcardLatestDetails(data) {
    if (data) {
      this.notifyBuildcardLatestDetailsSubject.next(data);
    }
  }

  public notifyProjectDetail(data) {
    if (data) {
      this.notifyProjectDetailSubject.next(data);
    }
  }

  public notifyComment(data) {
    if (data) {
      this.notifyCommentSubject.next(data);
    }
  }

  public notifyManagerDetail(data) {
    if (data) {
      this.nitifyManagerSubject.next(data);
    }
  }

  public notifyTodosqueries(data, action?) {
    if (data) {
      this.notifyTodosQueriesSubject.next({ data: data, action: action });
    }
  }

  public notifyProjectInbox(data, action?) {
    if (data) {
      this.notifyProjectInboxSubject.next({ data: data, action: action });
    }
  }

  public notifyDesignBoardDetail(data) {
    if (data) {
      this.notifyDesignBoardSubject.next(data);
    }
  }

  public notifyChecklistCoderepo(data) {
    if (data) {
      this.notifyChecklistRepoSubject.next(data);
    }
  }

  public notifyPhaseWiseFeedDetail(data) {
    if (data) {
      this.notifyPhaseWiseFeedSubject.next(data);
    }
  }

  public notifyPartnersListDetail(data) {
    if (data) {
      this.notifyPartnersListSubject.next(data);
    }
  }

  public notifyRecommendedList(data) {
    if (data) {
      this.notifyRecommendedListSubject.next(data);
    }
  }

  public notifyReleaseNoteDetail(data) {
    this.notifyReleaseNotesSubject.next(data);
  }

  public notifyBuilderPlanner(data) {
    if (data) {
      this.notifyBuilderPlannerSubject.next(data);
    }
  }
  public notifyFooterBuilderPlanner(data) {
    if (data) {
      this.notifyFooterBuilderPlannerSubject.next(data);
    }
  }
  public notifyAccountSetupDetail(data) {
    if (data) {
      this.notifyAccountSetupSubject.next(data);
    }
  }

  public notifyAllocationDetail(data) {
    if (data) {
      this.notifyAllocationsSubject.next(data);
    }
  }

  public notifyDownloadTimeLine(data) {
    if (data) {
      this.notifyDownloadTimeLineSubject.next(data);
    }
  }

  public notifySlackDetail(data) {
    if (data) {
      this.notifySlackSubject.next(data);
    }
  }

  public notifyBlockedReason(data) {
    if (data) {
      this.notifyBlockedReasonSubject.next(data);
    }
  }

  public notifyMyAccountDetail(data) {
    if (data) {
      this.notifyMyAccountSubject.next(data);
    }
  }

  public getUserDetail() {
    return window.localStorage.getItem('user')
      ? JSON.parse(window.localStorage.getItem('user'))
      : null;
  }

  public getUserName() {
    const userDetail = window.localStorage.getItem('user')
      ? JSON.parse(window.localStorage.getItem('user'))
      : null;
    if (userDetail) {
      return userDetail.first_name + ' ' + userDetail.last_name;
    } else {
      return null;
    }
  }

  public notifyMeeting(data) {
    if (data) {
      this.notifyMeetingSubject.next(data);
    }
  }

  public notifyScopeCR(data) {
    this.notifyScopeChangeRequest.next(data);
  }

  public notifyIdeaboardDetail(data) {
    if (data) {
      this.notifyIdeaboardSubject.next(data);
    }
  }

  public cancelSubscription(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  setUserProperties(data) {
    const properties = {
      userId: data.id ? data.id : '',
      first_name: data.first_name,
      last_name: data.last_name,
      user_email: data.email,
      country_code: data.country_code,
      user_role: data.user_role ? data.user_role : '',
      user_designation: data.designation ? data.designation : '',
      user_country_name: data.user_country_name ? data.user_country_name : ''
    };
    return properties;
  }

  public setLanguage(projectCurrency) {
    if (projectCurrency != null) {
      switch (projectCurrency) {
        case 'USD':
          this.locale = 'en-US';
          break;
        case 'INR':
          this.locale = 'en-IN';
          break;
        case 'MYR':
          this.locale = 'en-MY';
          break;
        case 'EUR':
          this.locale = 'en-EU';
          break;
        case 'GBP':
          this.locale = 'en-GB';
          break;
        case 'AED':
          this.locale = 'en-AE';
          break;
      }
    }
  }

  public getLocaledPrice(price) {
    if (price != null) {
      price = String(price);
    }
    if (price != null) {
      if (price.indexOf(',') !== -1) {
        price = price.split(',').join('');
      }
      let priceSliced = [];
      let decimalPart = '00';
      if (price.indexOf('.') !== -1) {
        priceSliced = price.split('.');
        price = priceSliced[0];
        decimalPart =
          priceSliced[1].toString().length === 1
            ? parseFloat(priceSliced[1] + '0')
            : priceSliced[1].slice(0, 2);
      }
      const formatter = new Intl.NumberFormat(this.locale);
      let formatedPrice = formatter.format(price);
      if (formatedPrice.indexOf('.') === -1) {
        formatedPrice =
          formatedPrice + Constants.PARAM_CONSTANTS.DECIMAL + decimalPart;
      }
      return formatedPrice;
    } else {
      return '0.00';
    }
  }

  public calculateTaxOnAmount(amount, tax) {
    return parseFloat(
      amount
        ? (
            parseFloat(amount) +
            (parseFloat(amount) * (tax ? parseFloat(tax) : 0)) / 100
          ).toFixed(2)
        : '0'
    );
  }

  getFormatedPhone(phone: string) {
    if (phone.charAt(0) === '-') {
      return phone.substring(1);
    } else if (
      (phone.charAt(0) !== '-' &&
        phone.charAt(0) === '+' &&
        phone.length >= 10) ||
      phone.length === 10
    ) {
      return phone.trim();
    } else if (phone.length >= 10 && phone.charAt(0) !== '+') {
      return '+' + phone.trim();
    } else {
      return '';
    }
  }

  public humanize(str) {
    const frags = str[0].split('_');
    for (let i = 0; i < frags.length; i++) {
      const index = i;
      frags[index] =
        frags[index].charAt(0).toUpperCase() + frags[index].slice(1);
    }
    return frags.join(' ');
  }

  getName(fname, lname): string {
    let val = '';
    if (fname && lname) {
      val = fname[0] + lname[0];
    } else {
      if (fname) {
        val = fname[0];
      } else if (lname) {
        val = lname[0];
      }
    }
    if (val == undefined) {
      val = '';
    }
    return val;
  }

  editorLink(event) {
    if (event.target.tagName.toLowerCase() === 'a') {
      event.target.classList.add('linkClicked');
      event.target.setAttribute('contenteditable', 'false');
    }
    //uncomment when using "unlink" in editor
    // else{
    //   if(document.querySelector('.linkClicked')){
    //       document.querySelectorAll('.linkClicked').forEach((item)=> {
    //           item.removeAttribute("contenteditable")
    //       });
    //   }
    // }
  }

  getCodesFromDewey(dewey_code: string) {
    if (dewey_code) {
      const stage = dewey_code[0];
      let codeToSplit = dewey_code.slice(1);

      let surface: string;

      if (dewey_code.includes('#')) {
        surface = dewey_code.split('#')[1];
        codeToSplit = codeToSplit.slice(0, -2);
      }

      const [box, flow, flow_category, sub_flow_category] =
        codeToSplit.split('.');

      return {
        stage,
        box,
        flow,
        flow_category,
        sub_flow_category,
        surface
      };
    } else {
      return {};
    }
  }

  uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        const randomNumber = array[0] / 0xffffffff;
        const r = (randomNumber * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  setCookies(newCookie: string, cookieValue: string, expirationDays?): void {
    document.cookie = `${newCookie}=${cookieValue}; expires=${expirationDays}; path=/; domain=${window.location.href?.replace('#', '')};`;
  }

  getCookiesByName(newCookie: string): string {
    const cookiesValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${newCookie}=`));

    if (cookiesValue) {
      return cookiesValue.split('=')[1];
    }
    return null;
  }

  private getInfoStoredInLocalStorage() {
    if (window.navigator) {
      return {
        builder_cookie_id: this.getCookiesByName('builder_cookie_id'),
        builder_session_start_time: this.getCookiesByName(
          'builder_session_start_time'
        ),
        builder_session_id: this.getCookiesByName('builder_session_id'),
        builder_session_latest_time: new Date().toString(),
        builder_buildcard_id: this.getCookiesByName('builder_buildcard_id')
      };
    }
    return {};
  }

  private getBroswerSpecificInfo() {
    if (window.navigator) {
      const match = window.navigator.userAgent.match(
        /(chrome|firefox|safari|opera|msie|trident(?=\/))\/?\s*(\d+)/i
      );
      return {
        browser: match?.[1]?.toLowerCase(),
        browser_version: match?.[2],
        user_agent: window.navigator.userAgent,
        builder_user_language:
          navigator.language || navigator['userLanguage'] || 'en-US',
        builder_user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezone: new Date().toISOString(),
        page_url: window.location.href,
        screen_res_height: window.screen.height,
        screen_res_width: window.screen.width,
        screen_view_height: window.innerHeight,
        screen_view_width: window.innerWidth,
        screen_colour_depth: window.screen.colorDepth,
        builder_user_os: this.getUserOS()
      };
    }
    return {};
  }

  private getUserOS() {
    const userAgent = navigator.userAgent;
    let os = 'Unknown';
    if (/Windows/.test(userAgent)) {
      os = 'Windows';
    } else if (/Mac OS|Macintosh/.test(userAgent)) {
      os = 'Mac OS';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/iOS/.test(userAgent)) {
      os = 'iOS';
    } else if (/Linux/.test(userAgent)) {
      os = 'Linux';
    }
    return os;
  }

  trackSegmentEventWithBrowserAndUserInfo(
    eventName,
    userData,
    dewey_code: string,
    projectData?,
    addtnlProps?
  ) {
    if (userData) {
      const { id, designation, email } = userData;
      const parser = new UAParser();
      const userBrowser = parser.getBrowser();
      const userDevice = parser.getDevice();
      const deweyCode = {
        dewey_code,
        event_description: eventName,
        ...this.getCodesFromDewey(dewey_code)
      };
      let projectObj = {} as any;
      if (projectData) {
        projectObj = {
          project_id: projectData.id,
          project_status: projectData.status,
          project_name: projectData.name,
          buildcard_id: projectData.build_card_id
        };
      }
      const prop = {
        user_designation: designation,
        user_id: id,
        browser: userBrowser,
        user_device: userDevice,
        internal_user_email: email,
        source_system: 'builder_360',
        event_time: new Date().toISOString(),
        source_environment: environment.name,
        ...this.getBroswerSpecificInfo(),
        ...this.getInfoStoredInLocalStorage(),
        ...deweyCode,
        ...projectObj,
        ...addtnlProps
      };
      this.trackSegmentEvent(eventName, prop);
    }
  }

  isloginSuccess() {
    this.isLoggedIn.next(true);
  }

  checkPastOrCurrentDate(date) {
    if (date) {
      const inputDate = new Date(date);
      if (isNaN(inputDate.getTime())) {
        return false;
      }
      const todaysDate = new Date();
      if (inputDate.setHours(0, 0, 0, 0) <= todaysDate.setHours(0, 0, 0, 0)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
